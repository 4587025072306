import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { Utilities } from '../class/utilities';
import { CashTitle, Receive } from '../interface/cash-title';
import { Invoice } from '../interface/invoice';
import { NFe } from '../interface/NF-e';
import { NFCe } from '../interface/NFC-e';
import { NFSe } from '../interface/NFS-e';
import { Finance } from '../interface/ro-finance';
import { RoFull } from '../interface/ro-full';
import { ClientService } from './client.service';
import { PaymentService } from './payment.service';
import { SupplierService } from './supplier.service';
import { TitleTypeService } from './title-type.service';
import { environment } from '../../environments/environment';
import { ExpenseService } from './expense.service';
import { ApiPaginatedTitleQueryParams, ApiPaginatedTitlesResponse, PaginationService } from './pagination.service';
import { Observable, firstValueFrom, of } from 'rxjs';
import { DataService } from './data.service';
import { API_ERRORS } from '../shared/lists/errors';
import { ObjectId } from '../shared/type-aliases/object-id';
import { IsoString } from '../shared/type-aliases/iso-string';
import { Address } from '../interface/address';
import { Company } from '../interface/company';
import { Supplier } from '../interface/supplier';
import { Moment } from 'moment';


export interface PaginatedTitle {
  readonly available?: 0 | 1;
  readonly _id?: ObjectId;
  readonly company?: ObjectId;
  readonly createdAt: IsoString;
  readonly updatedAt: IsoString;
  readonly seq: number;
  readonly __v: number;

  bank?: {
    code: string;
    name: string;
    operation: string;
    account: string;
  }
  companyCode?: string;
  type?: ObjectId;
  readonly supplier_client: {
    _id: ObjectId,
    name: string;
    document: string;
    vehicles: Array<string>;
    available: 0 | 1;
    gender: number;
    lang: number;
    maritalStatus: number;
    blocked: boolean;
    subscribed: boolean;
    kind: any;
    updateHistory: Array<any>;
    createdAt: IsoString;
    updatedAt: IsoString;
    rg: string;
    phone1: string;
    phone2: string;
    address: Address;
    lastUpdate: IsoString;
    type: any;
    documentType: number;
    hash: string,
    marketing: number,
    __v: number,
    fancyName?: string,
    birthday?: IsoString,
    taxpayer?: boolean,
    retCOFINS?: boolean,
    retCSLL?: boolean,
    retINSS?: boolean,
    retIRPJ?: boolean,
    retPIS?: boolean,
    lead?: any,
    email?: string,
    creditLimit?: number,
    cardAdmin?: Supplier['cardAdmin']
  },

  invoiceNumber?: string;
  paymentCondition: ObjectId;
  expenseType?: ObjectId;
  value: number;
  parcel: number;
  observation: string;
  balance: number;
  
  /**
 * When the Cash Titles are created from OS or Order, the status is inactive (0).
 * So, when OS is closed, all titles are updated to active (status = 1).
 * Whem a order has its status changed from "ORÇAMENTO"(1) to "PEDIDO"(2), his titles also
 * must be updated to active status.
 * 
 * If the title is created from invoice, the status is active directly (1).
 * 
 * Whan the title cannot be deleted (already have received lows), the status is seetted to canceled(2)
 * 
 * - 0 - inactive
 * - 1 - active  
 * - 2 - canceled
 * */
  status: 0 | 1 | 2;
  received: Receive[];
  adiantamento: number;
  expirationDate?: IsoString;
  movementDate: IsoString;
  rateTitle?: number;

  /** Local only */
  cnpj: string;
  fees?: number;
  penaltyFee?: number;
  rate?: number;
  discount?: number;
}


@Injectable({
  providedIn: 'root'
})
export class CashTitleService {

  private now = new Date();
  cashTitles: CashTitle[];

  private expenseTypes = [];
  private paymentConditions = [];
  private types = [];
  private persons = [];

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private expenseService: ExpenseService,
    private supplierService: SupplierService,
    private clientService: ClientService,
    private paymentService: PaymentService,
    private titleTypeService: TitleTypeService,
    private _dataService: DataService,
  ) { }

  async getAll(cnpj: string, skipInactives = true): Promise<CashTitle[]> {
    const url = `${environment.mkgoURL}/api/v1/title`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    await this.getData();
    const resp: any = await this.http.get<{ titles: any[] }>(url, header).pipe(
      first(),
      tap((resp) => {
        const wrongTitles = [];
        for (const title of resp.titles) {
          const ids = new Set(title.received.reduce((arr, received) => arr.concat(received._id), []));
          if (ids.size !== title.received.length) {
            wrongTitles.push(title)
          }
        }
        if (wrongTitles.length) {
          console.warn('Alguns títulos possuem baixas com o mesmo id', wrongTitles)
        }
      }),
    ).toPromise();
    let titles: CashTitle[] = [];
    for (let title of resp.titles) {
      const titleApp = await this.complyAPP(title);
      titles.unshift(titleApp)
    }
    this.cashTitles = skipInactives ? titles.filter(t => t.status !== 0) : titles;
    return titles;
  }

  async getTitlesBeforeExpirationDateStart(cnpj: string, expirationDate: Moment): Promise<PaginatedTitle[]> {
    const url = `${environment.mkgoURL}/api/v1/title/expiration-date`;
    let params = new HttpParams();

    params = params.append('expirationDate', expirationDate.toISOString());

    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    header['params'] = params;

    const response = await firstValueFrom(this.http.get(url, header).pipe(first()))

    return response as any;
  }

  getPaginated(cnpj: string, apiParams: ApiPaginatedTitleQueryParams, matrix?: Company): Observable<ApiPaginatedTitlesResponse<PaginatedTitle>> {
    const url = `${environment.mkgoURL}/api/v1/title/pages`;
    let params = PaginationService.getParams(apiParams);
    return this._dataService.httpOptions(cnpj, params).pipe(
      switchMap(options => this.http.get<ApiPaginatedTitlesResponse<PaginatedTitle>>(url, options)),
      first(),
      switchMap(resp => {
        // the API return 100 docs by defult (even if all=1), so if we want all docs we must call it again with param `limit`
        if (apiParams.all && resp.hasNextPage) {
          apiParams.limit = resp.totalDocs;
          params = PaginationService.getParams(apiParams);
          return this._dataService.httpOptions(cnpj, params).pipe(
            switchMap(options2 => this.http.get<ApiPaginatedTitlesResponse<PaginatedTitle>>(url, options2)),
            first()
          )
        } else {
          return of(resp)
        }
      }),
      map(resp => {
        resp.docs.map(title => {
          if (matrix && title.supplier_client['updateHistory']) {
            const clientHistory = title.supplier_client['updateHistory'].find(hist => hist.company === matrix.id);
            if (clientHistory) {
              title.supplier_client.name = clientHistory.name;
            }
          }
          title.cnpj = cnpj;
          return title
        });
        return resp;
      }),
      catchError(err => {
        /** If no one data is found, the API will return a error */
        if (err.error.error === API_ERRORS.notFound) {
          return of({
            docs: <PaginatedTitle[]>[],
            hasNextPage: false,
            hasPrevPage: false,
            limit: 0,
            nextPage: null,
            page: 0,
            pagingCounter: 0,
            prevPage: null,
            totalDocs: 0,
            totalPages: 0
          })
        } else {
          throw err;
        }
      }),
      map(resp => {
        resp.docs.map(t => {
          t.cnpj = cnpj || this.dataService.company.cnpj;
          const received = t.received;
          t.fees = received.reduce((sum, low) => sum + (low.fees || 0), 0);
          t.penaltyFee = received.reduce((sum, low) => sum + (low.penaltyFee || 0), 0);
          t.rate = received.reduce((sum, low) => sum + (low.rate || 0), 0);
          t.discount = received.reduce((sum, low) => sum + (low.discount || 0), 0);
          t.rateTitle = t.rateTitle ?? 0
        });
        return resp
      })
    );
  }

  async getById(id: string): Promise<CashTitle> {
    const url = `${environment.mkgoURL}/api/v1/title/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(false));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise()
    await this.getData();
    const title = this.complyAPP(resp);
    return title;
  }

  /**
   * @param personId ID of Client or Supplier
   * @returns an array of advance-titles
   */
  async getAdvancesOfPerson(personId: ObjectId): Promise<CashTitle[]> {
    const url = `${environment.mkgoURL}/api/v1/title/client/${personId}`;
    const options = await firstValueFrom(this.dataService.httpOptions(false));
    return firstValueFrom(this.http.get(url, options).pipe(
      first(),
      map((arr: any[]) => arr.map(obj => this.complyAPP(obj)))
    ));
  }

  /** @TODO improve this logic */
  private async getData() {
    const [expenseTypes, paymentConditions, types, clients, suppliers] = await Promise.all([
      this.expenseService.getAll(),
      this.paymentService.getAll(),
      this.titleTypeService.getAll(),
      this.clientService.getAll(),
      this.supplierService.getAll()
    ]);
    this.expenseTypes = expenseTypes;
    this.paymentConditions = paymentConditions;
    this.types = types;
    this.persons = Array.from(clients.values()).concat(suppliers)
  }

  async create(title: CashTitle, cnpj: string): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/title`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    const body = JSON.stringify(this.complyAPI(title));
    const resp = await firstValueFrom(this.http.post<{id: ObjectId}>(url, body, header));
    return resp.id;
  }

  async update(title: CashTitle | PaginatedTitle, cnpj: string) {
    const url = `${environment.mkgoURL}/api/v1/title/${title._id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    const body = JSON.stringify(this.complyAPI(title));
    const resp = await this.http.put(url, body, header).pipe(first()).toPromise();
    return resp;
  }


  async filter(
    args: {
      range?: {
        startDate?: string,
        endDate?: string
      }
      forceReceived?: boolean,
      totalByCondition?: boolean,
      balanceOtherThan?: number
    }
  ) {
    let url = `${environment.mkgoURL}/api/v1/title/filter`;
    let params = new HttpParams();

    if (args.range) {
      if (args.range.startDate) {
        params = params.append('startDate', args.range.startDate)
      }
      if (args.range.endDate) {
        params = params.append('endDate', args.range.endDate)
      }
    }

    if (args.hasOwnProperty('forceReceived')) {
      params = params.append('forceReceived', `${args.forceReceived}`)
    }
    if (args.hasOwnProperty('totalByCondition')) {
      params = params.append('totalByCondition', `${args.totalByCondition}`)
    }
    if (Number.isFinite(args.balanceOtherThan)) {
      params = params.append('balanceOtherThan', `${args.balanceOtherThan}`)
    }

    const header = await firstValueFrom(this.dataService.httpOptions(false));
    header['params'] = params;
    const resp = await this.http.get<{
      received: Receive,
      supplier_client: ObjectId,
      type: ObjectId
    }[]
    >(url, header).pipe(
      first(),
      map((titles) => titles.map((t: any) => {
        t.received = [t.received];
        return this.complyAPP(t)
      }))
    ).toPromise();
    return resp
  }

  public async delete(id: string, cnpj: string) {
    const url = `${environment.mkgoURL}/api/v1/title/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    const resp: any = await this.http.delete(url, header).pipe(first()).toPromise()
    return resp;
  }

  public findOsTitles(idOS: string, codeSystem: string | number, cnpj: string) {
    const url = `${environment.mkgoURL}/api/v1/title/osv2`;
    const params = PaginationService.getParams({
      idOS,
      companyCode: codeSystem
    });

    return this._dataService.httpOptions(cnpj, params).pipe(
      switchMap(options => this.http.get<{ titles: CashTitle[] }>(url, options)),
      first(),
      map(r => r.titles),
      catchError(err => {
        /** If no one data is found, the API will return a error */
        if (err.error.error === API_ERRORS.notFound) {
          return of([] as Array<CashTitle>)
        } else {
          throw err;
        }
      })
    )
  }

  public findOrderTitles(orderId: string, companyOrder: string | number, cnpj: string) {
    const url = `${environment.mkgoURL}/api/v1/title/osv3`;
    const params = PaginationService.getParams({
      orderId,
      companyOrder
    });

    return this._dataService.httpOptions(cnpj, params).pipe(
      switchMap(options => this.http.get<{ titles: CashTitle[] }>(url, options)),
      first(),
      map(r => r.titles),
      catchError(err => {
        /** If no one data is found, the API will return a error */
        if (err.error.error === API_ERRORS.notFound) {
          return of([] as Array<CashTitle>)
        } else {
          throw err;
        }
      })
    )
  }

  getTotals() {
    const url = `${environment.mkgoURL}/api/v1/title/totals`;
    return this._dataService.httpOptions(false).pipe(
      switchMap((options) => this.http.get<{
        balancePayTotal: number;
        balanceReceiveTotal: number;
        valuePayTotal: number;
        valueReceiveTotal: number;
        _id: ObjectId;
      }>(url, options)))
  }

  public getExpirationDate(days: number, from: Date = new Date()): Date {
    const milisseconds = days * Utilities.MS_DAY;
    let due = new Date(from.getTime() + milisseconds)

    // avoid set due at saturday or sunday, set to monday
    switch (due.getDay()) {
      case 6: // saturday
        due = new Date(this.now.getTime() + milisseconds + Utilities.MS_DAY * 2)
        break;
      case 0: // sunday
        due = new Date(this.now.getTime() + milisseconds + Utilities.MS_DAY)
      default:
        break;
    }
    return new Date(due.setHours(0, 0, 0, 0));
  }


  /** fix rounding difference */
  public fixDifference(titles: CashTitle[], invoiceValue: number): CashTitle[] {
    if (!titles.length) {
      return []
    }
    let sum = 0;
    for (const title of titles) {
      sum += title.value;
    }
    const difference = Number((invoiceValue - sum).toFixed(2));
    titles[0].value = Number(((titles[0].value || 0) + difference).toFixed(2));
    if (titles[0].balance !== undefined) {
      titles[0].balance = Number(((titles[0].balance || 0) + difference).toFixed(2));
    }
    return titles;
  }

  assemblyTitlesFromInvoice(invoice: Invoice, finance: Finance, titleType: string, expenseType: string, os: RoFull): CashTitle[] {
    let titles = [];
    for (const parcel of finance.paymentCondition.parcels) {
      const titleValue = Utilities.calcPercentage(parcel.percentage, finance.value);
      let title: CashTitle = {
        status: 1,
        value: titleValue,
        movementDate: new Date(invoice.dispatchDate),
        expirationDate: this.getExpirationDate(parcel.days),
        typeId: titleType,
        supplier_clientId: invoice.sender,
        paymentConditionId: finance.paymentCondition._id,
        expenseTypeId: expenseType,
        parcel: parcel.seq,
        invoiceNumber: invoice.number,
        serial: invoice.serial,
        balance: titleValue,
        adiantamento: false,
        rateTitle: 0
      }
      if (os && os.codeSystem) {
        title.companyCode = os.codeSystem.toString();
        title.idOS = os.id;
      }
      titles.push(title)
    }

    return this.fixDifference(titles, finance.value);
  }

  assemblyTitlesFromNF(invoice: NFe | NFCe, finance: Finance, titleType: string, expenseType: string, clientId?: string): CashTitle[] {
    let titles = [];

    for (const parcel of finance.paymentCondition.parcels) {
      const titleValue = Utilities.calcPercentage(parcel.percentage, finance.value);
      let title: CashTitle = {
        status: 1,
        value: titleValue,
        movementDate: new Date(),
        expirationDate: this.getExpirationDate(parcel.days),
        typeId: titleType,
        paymentConditionId: finance.paymentCondition._id,
        expenseTypeId: expenseType || "",
        parcel: parcel.seq,
        invoiceNumber: invoice.ide.nNF.toString(),
        serial: invoice.ide.serie.toString(),
        balance: titleValue,
        adiantamento: false,
        rateTitle: 0
      }
      if (clientId) {
        title['supplier_clientId'] = clientId;
      }
      titles.push(title)
    }

    return this.fixDifference(titles, finance.value);
  }


  assemblyTitlesFromNFSE(invoice: NFSe, finance: Finance, titleType: string, expenseType: string, clientId?: string): CashTitle[] {
    let titles = [];

    for (const parcel of finance.paymentCondition.parcels) {
      const titleValue = Utilities.calcPercentage(parcel.percentage, finance.value);
      let title: CashTitle = {
        status: 1,
        value: titleValue,
        movementDate: new Date(),
        expirationDate: this.getExpirationDate(parcel.days),
        typeId: titleType,
        paymentConditionId: finance.paymentCondition._id,
        expenseTypeId: expenseType || "",
        parcel: parcel.seq,
        invoiceNumber: invoice.RPS[0].RPSNumero.toString(),
        serial: invoice.RPS[0].RPSSerie,
        balance: titleValue,
        adiantamento: false,
        rateTitle: 0
      }
      if (clientId) {
        title['supplier_clientId'] = clientId;
      }
      titles.push(title)
    }

    return this.fixDifference(titles, finance.value);
  }

  private complyAPP(apiObject: any): CashTitle {
    const received: Receive[] = apiObject.received || [];
    const cashtitle: CashTitle = {
      _id: apiObject._id,
      idOS: apiObject.idOS,
      companyCode: apiObject.companyCode,
      typeId: apiObject.type,
      supplier_clientId: apiObject.supplier_client,
      expenseTypeId: apiObject.expenseType,
      paymentConditionId: apiObject.paymentCondition,
      invoiceNumber: apiObject.invoiceNumber,
      serial: apiObject.serial,
      observation: apiObject.observation,
      value: apiObject.value,
      parcel: apiObject.parcel,
      balance: apiObject.balance,
      status: apiObject.status,
      bank: apiObject.bank,
      adiantamento: apiObject.adiantamento === 1,
      received: received,
      fees: received.reduce((sum, low) => sum + (low.fees || 0), 0),
      penaltyFee: received.reduce((sum, low) => sum + (low.penaltyFee || 0), 0),
      rate: received.reduce((sum, low) => sum + (low.rate || 0), 0),
      discount: received.reduce((sum, low) => sum + (low.discount || 0), 0),
      rateTitle: apiObject.rateTitle || 0
    }

    if (apiObject.movementDate) {
      // prevent initial Date (1970-01-01)
      cashtitle.movementDate = new Date(apiObject.movementDate);
    }

    if (apiObject.expirationDate) {
      // prevent initial Date (1970-01-01)
      cashtitle.expirationDate = new Date(apiObject.expirationDate)
    }

    this.calculateBalance(cashtitle);

    cashtitle.expenseType = this.expenseTypes.find(expense => expense._id === cashtitle.expenseTypeId);
    cashtitle.paymentCondition = this.paymentConditions.find(payment => payment._id === cashtitle.paymentConditionId);
    cashtitle.type = this.types.find(_type => _type._id === cashtitle.typeId);
    cashtitle.supplier_client = this.persons.find(person => person.id === cashtitle.supplier_clientId)
    // this.calculateBalance(cashtitle)
    return cashtitle
  }

  private complyAPI(cashTitle: CashTitle | PaginatedTitle) {

    // reset payment method

    if (Object.hasOwn(cashTitle, 'rateValue')) {
      cashTitle['rateValue'] = undefined
    }

    if (Object.hasOwn(cashTitle, 'feesValue')) {
      cashTitle['feesValue'] = undefined
    }


    if (Object.hasOwn(cashTitle, 'penaltyFeeValue')) {
      cashTitle['penaltyFeeValue'] = undefined
    }

    if (Object.hasOwn(cashTitle, 'parcelValue')) {
      cashTitle['parcelValue'] = undefined
    }

    cashTitle.status = cashTitle.status || 0;
    cashTitle.rateTitle = cashTitle.rateTitle || 0;

    let apiObject: any = { ...cashTitle };
    apiObject.adiantamento = Number(cashTitle.adiantamento) || 0;
    // apiObject.type =  
    delete apiObject.toPay;
    delete apiObject.parcelValue;

    // removing Mongo properties
    delete apiObject.updatedAt;
    delete apiObject.createdAt;
    delete apiObject.company;
    delete apiObject.seq;
    delete apiObject.__v;
    delete apiObject._id;

    // throw error if have receives with same id
    if (cashTitle.received && Array.isArray(cashTitle.received)) {
      const ids = new Set(cashTitle.received.reduce((arr, receive) => arr.concat(receive._id), []));
      if (ids.size !== cashTitle.received.length) {
        throw new Error("Baixas com identificadores iguais")
      }
    }

    if (cashTitle.movementDate) {
      const movementDate = new Date(cashTitle.movementDate);
      if (movementDate.valueOf()) {
        apiObject.movementDate = movementDate.toISOString()
      }
    }

    if (cashTitle.expirationDate) {
      const expirationDate = new Date(cashTitle.expirationDate);
      if (expirationDate.valueOf()) {
        apiObject.expirationDate = expirationDate.toISOString()
      }
    }

    Object.keys(apiObject).forEach(key => {
      if ([undefined, null, ""].includes(apiObject[key])) {
        delete apiObject[key]
      }
    });

    if (cashTitle.type){
      if(typeof cashTitle.type === "string"){
        apiObject.type = cashTitle.type;
      } else {
        apiObject.type = cashTitle.type._id;
      }
      delete apiObject.typeId
    } else if(cashTitle['typeId']) {
      apiObject.type = cashTitle['typeId'];
    }

    if (apiObject.expenseTypeId) {
      apiObject.expenseType = apiObject.expenseTypeId;
      delete apiObject.expenseTypeId
    }

    if (apiObject.paymentConditionId) {
      apiObject.paymentCondition = apiObject.paymentConditionId;
      delete apiObject.paymentConditionId
    }

    if (apiObject.supplier_clientId) {
      apiObject.supplier_client = apiObject.supplier_clientId;
      delete apiObject.supplier_clientId
    }

    return apiObject
  }

  private calculateFees(toPay: number, cashTitle: CashTitle) {
    const feesValue = toPay ? Number((toPay * (cashTitle.fees / 100)).toFixed(2)) || 0 : 0;
    cashTitle.feesValue = Math.abs(feesValue);
  }

  private calculateRate(toPay: number, cashTitle: CashTitle) {
    const rateValue = toPay ? Number((toPay * (cashTitle.rate / 100)).toFixed(2)) || 0 : 0;
    cashTitle.rateValue = Math.abs(rateValue);
  }

  private calculatePenaltyFee(toPay: number, cashTitle: CashTitle) {
    const penaltyFeeValue = toPay ? Number((toPay * (cashTitle.penaltyFee / 100)).toFixed(2)) || 0 : 0;
    cashTitle.penaltyFeeValue = Math.abs(penaltyFeeValue);
  }

  calculateBalance(cashTitle: CashTitle) {
    cashTitle.toPay = cashTitle.balance;
    this.calculateFees(cashTitle.toPay, cashTitle);
    this.calculatePenaltyFee(cashTitle.toPay, cashTitle);
    this.calculateRate(cashTitle.toPay, cashTitle)
    cashTitle.toPay = Number((
      (cashTitle.toPay || 0)
      + (cashTitle.rateValue || 0)
      + (cashTitle.feesValue || 0)
      + (cashTitle.penaltyFeeValue || 0)
      - (cashTitle.discount || 0)
    ).toFixed(2));
  }

}

