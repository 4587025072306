import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from 'src/app/class/utilities';

@Pipe({
  name: 'percentageOfValue'
})
export class PercentageOfValuePipe implements PipeTransform {

  transform(value: number, fullValue): number {
    return Utilities.getPercentage(value, fullValue);
  }

}
