import { Pipe, PipeTransform } from '@angular/core';
import { AccessProfile } from 'src/app/component/page/company/access-profile/models/access-profile';

@Pipe({
  name: 'canAccess'
})
export class CanAccessPipe implements PipeTransform {

  transform(profile: AccessProfile, rule: string, verifyOnlyModule = false): boolean {
    if (!profile || !rule) {
      return true; // by default, allow the access
    }

    const module = profile.modules.find(m => m.name === rule.split('.').shift());
    if(!module){
      return false;
    }
    
    if (verifyOnlyModule && module) {
      return true; // can access the requested module
    }

    return module.tabs && module.tabs.some(t => t === rule); // can access the requested inner rule into module
  }

}
