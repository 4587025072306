import { Pipe, PipeTransform } from '@angular/core';
import { Part } from '../../interface/part';

const RESULTS_SIZE = 30;

@Pipe({
  name: 'partFilter'
})
export class PartFilterPipe implements PipeTransform {

  transform(parts: Part[], text = '', property = ''): Part[] {
    if (!parts) {
      return [];
    }
    if(!text){
      return parts.slice(0, RESULTS_SIZE)
    }
    text = text.toLowerCase();
    if (property) {
      const filtered: Part[] = [];
      for (let part of parts) {
        if (part[property].toLowerCase().includes(text)) {
          filtered.push(part);
          if (filtered.length == RESULTS_SIZE) {
            break;
          }
        }
      }
      return filtered;
    }

    const filtered: Part[] = [];
    for (let part of parts) {
      if (part.description.toLowerCase().includes(text) || (part.code && (part.code).toString().toLowerCase().includes(text)) || (part.fabricCode && (part.fabricCode).toString().toLowerCase().includes(text)) || (part.EANcode && (part.EANcode).toString().toLowerCase().includes(text))) {
        filtered.push(part);
        if (filtered.length == RESULTS_SIZE) {
          break;
        }
      }
    }
    return filtered
  }

}
